import React from "react";
import AddReviewForm from "../Components/AddReviewForm";

function AddReview() {
    return (
    <div className="home-section">
      <AddReviewForm />
    </div>
    );

}

export default AddReview;
